<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>{{ tituloPagina }}</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && !empresaEncontrada">
          <bloco-erro>Empresa não Encontrada</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && empresaEncontrada">
          <v-card>
            <v-card-text>
              <v-tabs v-model="tabs" color="orange darken-5">
                <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab color="orange"> Dados da Empresa</v-tab>
                <v-tab color="orange"> Dados Bancários e Documentos</v-tab>

                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="0">
                    <v-row class="mt-3 px-5">
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cnpj"
                            label="CNPJ"
                            :error-messages="erros.cnpj"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.razaoSocial && iconeErro) || ''"
                            v-mask="'##.###.###/####-##'"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.razaoSocial"
                            label="Razão Social"
                            :error-messages="erros.razaoSocial"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-domain"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.razaoSocial && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.habilitado"
                            label="Habilitação da Empresa"
                            :error-messages="erros.habilitado"
                            :items="itensSelect.habilitacao"
                            item-text="label"
                            item-value="valor"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-list-status"
                            outlined
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cep"
                            label="CEP da Empresa"
                            :error-messages="erros.cep"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-map-marker"
                            outlined
                            :append-icon="(!!erros.cep && iconeErro) || ''"
                            v-mask="'#####-###'"
                            @keyup="buscarCep()"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cidade"
                            label="Cidade"
                            :error-messages="erros.cidade"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-map"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.cidade && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouro"
                            label="Logradouro"
                            :error-messages="erros.logradouro"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouro && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouro_numero"
                            label="Numero"
                            :error-messages="erros.logradouro_numero"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouro_numero && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.bairro"
                            label="Bairro"
                            :error-messages="erros.bairro"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.bairro && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.complemento"
                            label="Complemento"
                            :error-messages="erros.complemento"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.complemento && iconeErro) || ''"
                        />
                      </v-col>


                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.uf"
                            label="UF"
                            :error-messages="erros.uf"
                            :items="itensSelect.ufs"
                            item-text="label"
                            item-value="id"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-map"
                            outlined
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.ddd"
                            label="DDD"
                            :error-messages="erros.ddd"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.ddd && iconeErro) || ''"
                            v-mask="'##'"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.telefone"
                            label="Telefone"
                            :error-messages="erros.telefone"
                            background-color="white"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.telefone && iconeErro) || ''"
                            v-mask="mascaraTelefone"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ma-5 float-right">
                      <v-btn
                          dark
                          color="orange"
                          @click="tabs = 1"
                      >Próximo passo
                        <v-icon>mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </v-row>

                  </v-tab-item>
                  <v-tab-item :key="1">
                    <v-row class="mt-3 px-5">
                      <v-col cols="12" md="6">
                        <AutocompleteBancos
                            :id="formulario.banco"
                            v-on:bancoSelecionado="bancoRetornado"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <AutocompleteTipoConta
                            :id="formulario.tipo_conta"
                            v-on:tipoContaSelecionado="tipoContaRetornado"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.agencia"
                            label="Agencia"
                            :error-messages="erros.agencia"
                            background-color="white"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.agencia && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.conta"
                            label="Conta"
                            :error-messages="erros.conta"
                            background-color="white"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.conta && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row>
                    <div class="mt-4 ml-2">
                      <span class="font-weight-bold font-size-16">Documentos</span>
                    </div>

                    <v-row class="mt-3 px-5">
                      <v-col cols="12" md="4">
                        <v-file-input
                            accept="image/*,.pdf"
                            label="Contrato Social"
                            counter
                            outlined
                            dense
                            hide-details
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment mdi-rotate-45"
                            v-model="formulario.doc_contrato_social"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-file-input
                            accept="image/*,.pdf"
                            label="Creci"
                            counter
                            outlined
                            dense
                            hide-details
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment mdi-rotate-45"
                            v-model="formulario.doc_creci"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-file-input
                            accept="image/*,.pdf"
                            label="Doc. do Responsável (CNH ou RG)"
                            counter
                            outlined
                            dense
                            hide-details
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment mdi-rotate-45"
                            v-model="formulario.doc_responsavel"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" align="right">
                        <div class="mt-5">
                          <v-btn
                              :block="blockBtns"
                              dark
                              :class="{'mr-4': !blockBtns,'mb-4': blockBtns}"
                              depressed
                              color="orange"
                              @click="confirmarSaida()"
                          >
                            Ir para Listagem
                            <v-icon right>
                              mdi-table
                            </v-icon>
                          </v-btn>

                          <v-btn
                              :block="blockBtns"
                              dark
                              depressed
                              color="var(--cor-primaria-100)"
                              @click="submit()"
                          >
                            {{ tipoSubmit === "cadastrar" ? "Registrar" : "Editar" }}
                            <v-icon right>
                              mdi-send-check
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import empresasServices from "../../services/empresas";
import helpers from "@/assets/js/helpers";
import viaCepServices from "../../services/viaCep";
import AutocompleteBancos from "@/components/autocomplete/Bancos";
import AutocompleteTipoConta from "@/components/autocomplete/TipoContaBanco";

export default {
  name: "Formulario",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    AutocompleteBancos,
    AutocompleteTipoConta
  },
  data() {
    return {
      tabs: null,
      empresaEncontrada: true,
      idEmpresa: null,
      formulario: {
        razaoSocial: null,
        cnpj: null,
        habilitado: null,
        cep: null,
        logradouro: null,
        logradouro_numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        ddd: null,
        telefone: null,
        banco: null,
        tipo_conta: null,
        agencia: null,
        conta: null,
        doc_contrato_social: '',
        doc_creci: '',
        doc_responsavel: '',
      },
      erros: {
        razaoSocial: null,
        cnpj: null,
        habilitado: null,
        cep: null,
        logradouro: null,
        logradouro_numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        ddd: null,
        telefone: null,
        banco: null,
        tipo_conta: null,
        agencia: null,
        conta: null,
        doc_contrato_social: '',
        doc_creci: '',
        doc_responsavel: '',
      },
    };
  },
  computed: {
    iconeErro() {
      return "mdi-alert-octagram";
    },
    permissaoPagina() {
      return this.permissaoMaster;
    },
    tipoSubmit() {
      return this.$route.meta.submit;
    },
    tituloPagina() {
      if (this.tipoSubmit === "cadastrar") {
        return "Cadastrar Empresa";
      }
      return "Editar Empresa";
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
    itensSelect() {
      const ufs = helpers.obterUfs();

      return {
        ufs,
        habilitacao: [
          {
            valor: 0,
            label: "Desabilitada",
          },
          {
            valor: 1,
            label: "Habilitada",
          },
        ],
      };
    },
    mascaraTelefone() {
      const telefone = this.formulario.telefone;

      if (!telefone) return;

      const tamanho = telefone.replace("-", "").length;

      if (tamanho <= 7) return "###-####";
      if (tamanho == 8) return "####-####";

      return "#####-####";
    },
  },
  methods: {
    buscarEmpresa() {
      const id = this.$route.params.id;
      const empresa = this.empresas.find((e) => Number(e.id) === Number(id));

      if (!empresa) {
        this.empresaEncontrada = false;
      } else {
        this.idEmpresa = empresa.id;

        this.formulario.razaoSocial = empresa.razaoSocial;
        this.formulario.cnpj = empresa.cnpj;
        this.formulario.habilitado = empresa.habilitado;
        this.formulario.cep = empresa.cep;
        this.formulario.logradouro = empresa.logradouro;
        this.formulario.logradouro_num = empresa.logradouro_num;
        this.formulario.complemento = empresa.complemento;
        this.formulario.bairro = empresa.bairro;
        this.formulario.cidade = empresa.cidade;
        this.formulario.uf = empresa.uf;
        this.formulario.ddd = empresa.ddd;
        this.formulario.telefone = empresa.telefone;
        this.formulario.banco = empresa.banco;
        this.formulario.tipo_conta = empresa.tipo_conta;
        this.formulario.agencia = empresa.agencia;
        this.formulario.conta = empresa.conta;
        // this.formulario.doc_contrato_social = empresa.doc_contrato_social;
        // this.formulario.doc_creci = empresa.doc_creci;
        // this.formulario.doc_responsavel = empresa.doc_responsavel;
      }
    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Empresas ?").then((res) => {
        if (res) {
          this.navegar("empresasIndex");
        }
      })
    },
    submit() {
      // if (!this.habilitacaoSubmit) return;

      if (this.tipoSubmit === "cadastrar") return this.cadastrar();
      return this.atualizar();
    },
    async cadastrar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await empresasServices.cadastrar(this.formulario);
        this.notificacaoSucesso("Cadastro efeutado com Sucesso !");
        this.navegar("empresasIndex");
      } catch (e) {
        if (this.obterCodigoRequisicao(e) === 422) {
          for (var key in e.response.data.erros) {
            this.interpretarErro(e.response.status, e.response.data.erros[key][0]);
          }
        }
      } finally {
        this.desativarLoading();
      }
    },
    async atualizar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await empresasServices.editar(this.idEmpresa, this.formulario);
        this.notificacaoSucesso("Empresa Editada");
        this.navegar("empresasIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    async buscarCep() {
      const regexCep = /^\d{1,5}-\d{3}$/;
      if (!regexCep.test(this.formulario.cep)) return;

      this.ativarLoading();
      try {
        let response = await viaCepServices.buscar(this.formulario.cep);
        let status = helpers.isset(() => response.status) ? response.status : false;

        if (status === 200 && !response.data.erro) {
          this.formulario.uf = response.data.uf;
          this.formulario.logradouro = response.data.logradouro;
          this.formulario.bairro = response.data.bairro;
          this.formulario.cidade = response.data.localidade;
        } else {
          this.setNotificacao({
            status: true,
            mensagem: "CEP não encontrado",
            categoria: 404,
          });
        }
      } catch (error) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao buscar CEP",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    bancoRetornado(value) {
      this.formulario.banco = value
    },
    tipoContaRetornado(value) {
      this.formulario.tipo_conta = value
    },
  },
  watch: {},
  created() {
    if (this.tipoSubmit === "editar") {
      this.buscarEmpresa();
    }
  },
};
</script>